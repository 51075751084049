export const servicesContent = {
  customMade: {
    columns: [
      {
        image: "agileProjects",
        title: "cards.agileProjects",
        subtitle: "cards.agileProjectsDescription",
      },
      {
        image: "devops",
        title: "cards.devops",
        subtitle: "cards.devopsDescription",
      },
      {
        image: "developerOnDemand",
        title: "cards.developerOnDemand",
        subtitle: "cards.developerOnDemandDescription",
      },
    ],
  },
  howWeWork: {
    columns: [
      {
        title: "services.howWeWork.deliveryWeekly",
        subtitle: "services.howWeWork.weCarryOutWeeklyDeliveries",
      },
      {
        title: "services.howWeWork.dedicatedTeams",
        subtitle: "services.howWeWork.fullTimeTeams",
      },
      {
        title: "services.howWeWork.deliveryCycle",
        subtitle: "services.howWeWork.withReportHoursUsed",
      },
    ],
  },
}
