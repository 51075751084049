import { WidthLimitation } from "@components/Content"
import React from "react"
import { Box, Flex, Text } from "rebass"

export const Wrapper = props => (
  <Flex flexDirection="column" width="100vw" {...props} />
)

export const ServicesBox = props => <Box maxWidth="250px" {...props} />

export const CustomMadeCard = props => (
  <Box
    sx={{
      width: "100%",
      maxWidth: "250px",
      svg: {
        height: 200,
      },
    }}
    {...props}
  />
)

export const ServiceDescription = props => (
  <Text
    as="p"
    sx={{
      fontWeight: "light",
      fontSize: 2,
    }}
    {...props}
  />
)

export const ServicesWrapper = props => (
  <Flex
    flexWrap="wrap"
    marginTop="10"
    justifyContent="space-around"
    sx={{
      "& > div": {
        paddingTop: 3,
        "&:not(:last-child)": {
          marginRight: [0, 3, 3],
        },
      },
    }}
    {...props}
  />
)

export const CustomMadeWrapper = props => (
  <Flex
    flexWrap="wrap"
    justifyContent="space-around"
    sx={{
      "& > div": {
        paddingTop: 4,
        "&:not(:last-child)": {
          marginRight: [0, 3, 3],
        },
      },
    }}
    {...props}
  />
)

export const Section = ({ alternative, children, ...props }) => (
  <Box
    as="section"
    {...{
      padding: "70px 20px",
      textAlign: "center",
      width: "100%",
      justifyContent: "center",
      display: "flex",
      backgroundColor: alternative ? "grayLight" : "unset",
      color: "blueNavy",
    }}
    {...props}
  >
    <WidthLimitation flexDirection="column">{children}</WidthLimitation>
  </Box>
)

export const SectionSubTitle = props => (
  <Text
    as="p"
    sx={{
      fontSize: 2,
      fontFamily: "heading",
      fontWeight: "light",
      color: "blueNavy",
    }}
    {...props}
  />
)
export const SectionTitle = props => (
  <Text
    as="h3"
    sx={{
      fontSize: 5,
      fontWeight: "body",
      color: "blueNavy",
      fontFamily: "heading",
    }}
    {...props}
  />
)

export const ServiceTitle = props => (
  <Text
    as="h4"
    sx={{
      fontSize: 3,
      fontWeight: "body",
      color: "blueNavy",
      fontFamily: "heading",
    }}
    {...props}
  />
)
