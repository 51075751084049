import BaseLayout from "@components/BaseLayout"
import Hero from "@components/Hero"
import SEO from "@components/Seo"
import Services from "@components/Services"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"

function ServicesPage() {
  const { metatag } = useStaticQuery(graphql`
    query Services {
      metatag: markdownRemark(
        fileAbsolutePath: { glob: "**/content/metatags/servicos.md" }
      ) {
        frontmatter {
          languages {
            language
            keywords
            title
            description
          }
        }
      }
    }
  `)
  const metatags = metatag.frontmatter.languages.find(
    lang => lang.language === process.env.GATSBY_LANGUAGE
  )

  return (
    <BaseLayout
      hero={
        <Hero title="services.hero.title" subtitle="services.hero.subtitle" />
      }
    >
      <SEO {...metatags} />
      <Services />
    </BaseLayout>
  )
}

export default ServicesPage
