import ImagesSVG from "@components/ImagesSVG"
import Translate from "@components/Translate"
import { servicesContent } from "@constants/services"
import React from "react"
import * as Style from "./Services.styles"

function Services() {
  const showTechSection = false

  return (
    <Style.Wrapper>
      <Style.Section>
        <Style.SectionTitle mb="3">
          <Translate id="services.howWeWork.howWeWork" />
        </Style.SectionTitle>
        <Style.SectionSubTitle mb="5">
          <Translate id="services.howWeWork.ourTechnologyTeam" />
        </Style.SectionSubTitle>
        <Style.ServicesWrapper>
          {servicesContent.howWeWork.columns.map((column, index) => (
            <Style.ServicesBox key={index}>
              <Style.ServiceTitle>
                <Translate id={column.title} />
              </Style.ServiceTitle>
              <Style.ServiceDescription mt={2}>
                <Translate id={column.subtitle} />
              </Style.ServiceDescription>
            </Style.ServicesBox>
          ))}
        </Style.ServicesWrapper>
      </Style.Section>
      <Style.Section alternative>
        <Style.SectionTitle>
          <Translate id="services.customMade.customMadeOnDemand" />
        </Style.SectionTitle>
        <Style.CustomMadeWrapper>
          {servicesContent.customMade.columns.map((column, index) => (
            <Style.CustomMadeCard key={index}>
              <ImagesSVG svg={column.image} />
              <Style.ServiceTitle>
                <Translate id={column.title} />
              </Style.ServiceTitle>
              <Style.ServiceDescription mt={2}>
                <Translate id={column.subtitle} />
              </Style.ServiceDescription>
            </Style.CustomMadeCard>
          ))}
        </Style.CustomMadeWrapper>
      </Style.Section>
      {showTechSection && (
        <Style.Section>
          <Style.SectionTitle>
            <Translate id="services.technology.technology" />
          </Style.SectionTitle>
          <Style.SectionSubTitle>
            <Translate id="services.technology.checkOutSomeTechnologiesWeServe" />
          </Style.SectionSubTitle>
        </Style.Section>
      )}
    </Style.Wrapper>
  )
}

export default Services
